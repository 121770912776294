'use client';

import Cookies from 'js-cookie';

const COOKIE_CONSENT_KEY = 'COOKIE_CONSENT';

export const COOKIE_LEVELS = {
  ['NECESSARY' as const]: 1,
  ['ANALYTICS' as const]: 2,
  ['MARKETING' as const]: 3,
};

export const getCookiesConsent = () => {
  const valueAsString = Cookies.get(COOKIE_CONSENT_KEY);

  if (!valueAsString) {
    return;
  }

  return Number.parseInt(valueAsString, 10);
};

export const setCookiesConsent = (level: number) =>
  Cookies.set(COOKIE_CONSENT_KEY, level.toString(), { expires: 365, domain: `.${window.location.hostname}` });
